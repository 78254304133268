import { gql } from 'graphql-tag';

export const GQL_UPDATE_SUB_STEP = gql`
    mutation ($input: UpdateCareerSubStepInput!) {
        updateCareerSubStep(input: $input) {
            careerSubStep {
                id
                finished
            }
        }
    }
`;

export const GQL_GET_USER = gql`
    query {
        currentUser {
            id
            profile {
                fullName
            }
            meetings {
                id
                code
                description
                type
                careerAdvisor {
                    id
                    email
                    profile {
                        id
                        firstName
                        fullName
                        phone
                        city {
                            id
                            name
                        }
                        profilePicture {
                            id
                            url
                            thumbnailUrl
                        }
                    }
                }
                status
                plannedOn
                title
            }
            careerProfile {
                id
                careerAdvisor {
                    id
                    email
                    profile {
                        id
                        firstName
                        fullName
                        phone
                        city {
                            id
                            name
                        }
                        profilePicture {
                            id
                            url
                            thumbnailUrl
                        }
                    }
                }
                careerSteps {
                    id
                    subSteps {
                        id
                        finished
                        type
                        report
                        voucher
                        testUrl
                    }
                }
            }
        }
    }
`;

export const CREATE_MEETING = gql`
    mutation CreateMeeting($input: MeetingInput!) {
        createMeeting(input: $input) {
            id
            owner {
                id
                email
            }
            careerAdvisor {
                id
                email
            }
            code
            status
            title
            description
            finishedOn
            cancelledOn
            plannedOn
            type
        }
    }
`;

export const SEND_MEETING_TO_USER = gql`
    mutation SendMeetingToUser($id: Long!) {
        sendMeetingInvitationToUser(meetingId: $id) {
            id
        }
    }
`;

export const SEND_MEETING_TO_ADVISOR = gql`
    mutation SendMeetingToAdvisor($id: Long!) {
        sendMeetingInvitationToAdvisor(meetingId: $id) {
            id
        }
    }
`;
