import { gql } from 'graphql-tag';

export const GQL_GET_USER = gql`
    query currentUser {
        currentUser {
            id
            email
            profile {
                city {
                    id
                    name
                }
                firstName
                lastName
                middleName
                newsletters {
                    id
                    uid
                    title
                }
            }
            daysBetweenAlertingMails
            preferredEmail
        }
    }
`;

export const GQL_SAVE_USER = gql`
    mutation ($input: UpdateUserInput!) {
        updateUser(input: $input) {
            user {
                id
            }
        }
    }
`;

export const GQL_CITIES = gql`
    query ($filter: CityFilterInput!) {
        cities(pagination: { page: 0, pageSize: 50 }, filter: $filter) {
            cities {
                id
                name
            }
        }
    }
`;

export const GQL_NEWSLETTERS = gql`
    query getNewsletters {
        newsletters(pagination: { page: 0, pageSize: 100 }, filter: { search: "" }) {
            count
            newsletters {
                id
                uid
                title
                description
                zohoId
            }
        }
    }
`;
