import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useQuery } from '@apollo/client';

import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';

import { Button, InputField, SelectField } from 'shared';

import { mapToSelect } from 'helpers';
import { GET_REGIONS } from './queries';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0 auto',
        maxWidth: '1400px'
    },
    compactRoot: {
        flexWrap: 'nowrap'
    },
    loading: {
        pointerEvens: 'none',
        opafield: '.5'
    },
    barWrapper: {
        background: theme.palette.background.primaryContrast,
        borderRadius: '60px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(3.375),

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3.375, 1),
            flexFlow: 'column nowrap',
            borderRadius: '25px'
        }
    },
    searchBarFields: {
        display: 'inherit',
        flexFlow: 'inherit',
        flex: '1 0 auto'
    },
    searchBarFieldsMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    compact: {
        borderRadius: 0,
        padding: `${theme.spacing(3.375)}px 0`
    },
    input: {
        background: theme.palette.background.default,
        border: 'none',
        borderRadius: '35px',
        flex: '2',
        height: '61px',
        margin: `0 ${theme.spacing(1)}px`,

        [theme.breakpoints.up('lg')]: {
            width: '280px'
        },
        '& .MuiInputBase-input': {
            border: 'none',
            borderRadius: '35px',
            padding: `${theme.spacing(2.625)}px ${theme.spacing(2.75)}px`
        },
        '& .MuiInputBase-input:focus': {
            border: 'none',
            boxShadow: '0 0 0 4px ' + theme.palette.background.dark
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5)
        }
    },
    select: {
        background: theme.palette.background.default,
        border: 'none',
        borderRadius: '35px',
        height: '61px',
        margin: `0 ${theme.spacing(1)}px`,
        padding: `${theme.spacing(1.75)}px ${theme.spacing(3)}px`,

        [theme.breakpoints.up('lg')]: {
            width: '300px'
        },
        '&:focus, &:active': {
            background: theme.palette.background.default,
            border: 'none',
            borderRadius: '35px',
            boxShadow: '0 0 0 4px ' + theme.palette.background.dark
        }
    },
    button: {
        marginBottom: 0,
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginTop: 0
    },
    filterButton: {
        marginTop: theme.spacing(1)
    },
    '.MuiFormControl-root': {
        // heigth: '100%'
    },
    title: {
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(1)
    },
    recent: {
        alignItems: 'center',
        display: 'flex',
        padding: `0 ${theme.spacing(6)}px`
    },
    item: {
        border: `1px solid ${theme.palette.primary.light}`,
        borderRadius: '35px',
        color: theme.palette.primary.main,
        fontSize: '1em',
        marginLeft: theme.spacing(1.3),
        padding: `${theme.spacing(0.4)}px ${theme.spacing(1.8)}px`
    },
    label: {
        color: theme.palette.text.disabled
    },
    selectRoot: {
        margin: 0,
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(1.5)
        }
    }
}));

const SearchBar = (props) => {
    const {
        onChange,
        initialValue = false,
        loading = false,
        hasFilters = false,
        accordion = false,
        filterClick
    } = props;
    const { t } = useTranslation();
    const classes = useStyles();

    const [search, setSearch] = useState(initialValue.search || '');
    const [region, setRegion] = useState(initialValue.region || '');
    const [searchState, setSearchState] = useState(false);
    const [width] = React.useState(window.innerWidth);

    const { data = {} } = useQuery(GET_REGIONS);
    const { regions = {} } = data;

    const handleSearch = () => {
        setSearchState(false);
        onChange({
            search,
            region
        });
    };

    useEffect(() => {
        if (!loading) {
            if (initialValue.search) setSearch(initialValue.search);
            if (initialValue.region) setRegion(initialValue.region);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    return (
        <Grid className={classNames(classes.root, { [classes.loading]: loading })} container direction="column">
            <Grid>
                <div className={classes.barWrapper}>
                    <div className={classes.searchBarFields}>
                        <InputField
                            placeholder={t('candidateSearchBar.search')}
                            className={classes.input}
                            value={search}
                            onChange={(value) => setSearch(value)}
                            label={false}
                            onKeyPress={handleKeyPress}
                        />
                        <SelectField
                            initialValue={region || null}
                            className={classes.select}
                            rootClass={classes.selectRoot}
                            options={mapToSelect(regions.regions, 'description', 'id')}
                            option={region}
                            helper={false}
                            placeholder={t('searchBar.organisation')}
                            onChange={(value) => setRegion(value)}
                            onKeyPress={handleKeyPress}
                        />
                    </div>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        label={t('candidateSearchBar.button')}
                        onClick={() =>
                            width < 980 && !searchState && accordion ? setSearchState(true) : handleSearch()
                        }
                        iconRight={() => <SearchIcon />}
                    />
                    {width < 960 && hasFilters && (
                        <Button
                            className={[classes.button, classes.filterButton]}
                            variant="contained"
                            color="primary"
                            label="Filteren"
                            onClick={filterClick}
                        />
                    )}
                </div>
            </Grid>
        </Grid>
    );
};

export default SearchBar;
