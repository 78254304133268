import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PasswordForm } from 'shared';
import { useHistory, useParams } from 'react-router-dom';
import { FINISH_REGISTRATION } from './queries';
import { useMutation } from '@apollo/client';
import { useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '1550px',
        width: '100%',
        padding: theme.spacing(0, 3),
        margin: theme.spacing(12) + 'px auto'
    }
}));

const PasswordChange = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const [succesMessage, setSuccesMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [setPassword, { loading: mutationLoading, data, errors }] = useMutation(FINISH_REGISTRATION);

    const handlePasswordChange = (password) => {
        if (password) {
            setPassword({
                variables: {
                    input: {
                        password: password,
                        token: id
                    }
                }
            });
        }
    };

    const showSuccesMessage = useCallback(() => {
        setSuccesMessage(true);
        setTimeout(() => {
            history.push('/login');
        }, 500);
    }, [history]);

    const showErrorMessage = useCallback(() => {
        setErrorMessage(true);
    }, []);

    useEffect(() => {
        if (data) {
            showSuccesMessage();
        }
        if (errors) {
            showErrorMessage(true);
        }
    }, [data, errors, showErrorMessage, showSuccesMessage]);

    return (
        <Grid className={classes.root} justify="center" container>
            {mutationLoading && <p>loading...</p>}

            <Grid className={classes.wrapper} item xs={6}>
                <PasswordForm
                    title={t('register.passwordReset')}
                    subTitle={t('register.passwordResetTitle')}
                    onChange={handlePasswordChange}
                />
            </Grid>
            {succesMessage && <Typography>{t('passwordReset.succesMessage')}</Typography>}
            {errorMessage && <Typography>{t('passwordReset.errorMessage')}</Typography>}
        </Grid>
    );
};

export default PasswordChange;
