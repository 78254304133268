import { gql } from 'graphql-tag';

export const GET_USER = gql`
    query {
        currentUser {
            id
            email
            lastLogin
            firstLogin
            skillTestReset
            skillTestStartedOn
            skillTestResetOn
            skillTestArchivedCount
            roles {
                id
                name
                title
            }
            profile {
                dateOfBirth
                firstName
                middleName
                lastName
                fullName
            }
            meetingsBatch
            meetings {
                id
                code
                description
                type
                careerAdvisor {
                    id
                    email
                    profile {
                        id
                        firstName
                        lastName
                        fullName
                        phone
                        city {
                            id
                            name
                        }
                        profilePicture {
                            id
                            url
                            thumbnailUrl
                        }
                    }
                }
                batch
                status
                plannedOn
                title
            }
            corporation {
                id
                name
                domain
                academyUrl
                hasAcademyUrl
            }
            careerProfile {
                id
                tasks {
                    id
                    finished
                    name
                    url
                }
                careerAdvisor {
                    id
                    email
                    profile {
                        id
                        firstName
                        fullName
                        phone
                        city {
                            id
                            name
                            province
                        }
                        profilePicture {
                            url
                            thumbnailUrl
                        }
                    }
                }

                careerSteps {
                    id
                    subSteps {
                        id
                        finished
                        type
                        report
                        voucher
                        testUrl
                    }
                }
            }
        }
    }
`;
