import React from 'react';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Link, Linkedin, Spotify, Facebook, Button } from 'shared';
// import { Instagram } from 'shared';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        borderTop: `1px solid ${theme.palette.borderColor.main}`,
        width: '100%',
        marginTop: theme.spacing(3)
    },
    root: {
        maxWidth: '1440px',
        width: '100%',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: `${theme.spacing(1.875)}px 0`,

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    linkMenu: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row'
        }
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.secondary,
        fontWeight: 500,
        transition: 'color 250ms',

        [theme.breakpoints.up('md')]: {
            marginRight: `${theme.spacing(5)}px`,

            '&:hover': {
                color: theme.palette.text.hover
            }
        }
    },
    copyright: {
        color: theme.palette.text.secondary,
        textAlign: 'center',
        paddingTop: '0!important', //ugly :(
        paddingBottom: '0!important',

        [theme.breakpoints.up('md')]: {
            marginRight: 'auto',
            textAlign: 'left'
        }
    },
    button: {
        height: '51px',
        width: '51px',
        lineHeight: '51px',
        minWidth: '51px',
        borderRadius: '26px',
        padding: 0,
        margin: theme.spacing(0, 1),

        '& .MuiSvgIcon-root': {
            width: '23px',
            height: '20px',
            color: theme.palette.primary.main
        },

        [theme.breakpoints.up('md')]: {
            margin: theme.spacing(0, 0, 0, 3),

            '&:hover': {
                background: theme.palette.primary.main,
                borderColor: theme.palette.primary.main,
                '& .MuiSvgIcon-root': {
                    color: theme.palette.primary.contrastText
                }
            }
        }
    }
}));

const Footer = (props) => {
    const { t } = useTranslation();
    const { links } = props;
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Grid className={classes.root} spacing={6} container>
                <Grid item>
                    <Typography className={classes.linkMenu}>
                        {links &&
                            links.map((link, idx) => (
                                <Link key={idx} to={link.path} variant="body2" className={classes.link}>
                                    {link.label}
                                </Link>
                            ))}
                    </Typography>
                </Grid>
                <Grid item className={classes.copyright}>
                    <Typography>{t('footer.copyright')}</Typography>
                </Grid>
                <Grid item>
                    <Button
                        className={classes.button}
                        variant="outlined"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/FLOWcorporaties/?ref=hl"
                        iconRight={() => <Facebook />}
                    />
                    <Button
                        className={classes.button}
                        variant="outlined"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/FLOWcorporaties"
                        iconRight={() => <TwitterIcon />}
                    />
                    <Button
                        className={classes.button}
                        variant="outlined"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.linkedin.com/company/flow-fonds-leren-en-ontwikkelen-wooncorporaties"
                        iconRight={() => <Linkedin />}
                    />
                    <Button
                        className={classes.button}
                        variant="outlined"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://open.spotify.com/show/6yTRfla4GmSMa6fUa8kBKP?si=4c66b96b1fc5425f"
                        iconRight={() => <Spotify />}
                    />
                    {/*
                    <Button
                        className={classes.button}
                        variant="outlined"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="TODO URL NAAR INSTAGRAM"
                        iconRight={() => <Instagram />}
                    />
                    */}
                </Grid>
            </Grid>
        </div>
    );
};
export default Footer;
