import React, { useState, useEffect } from 'react';
import { FORGOT_PASSWORD } from './queries';
import { Grid, Typography, Box } from '@material-ui/core';
import { InputField, Button } from 'shared';
import { Link } from 'react-router-dom';
import { LoaderModal } from 'shared';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'hooks';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '520px',
        width: '100%',
        padding: theme.spacing(0, 3),
        margin: theme.spacing(12) + 'px auto'
    },
    input: {
        width: '100%'
    },
    button: {
        width: '100%',
        marginTop: theme.spacing(2.5)
    },
    link: {
        display: 'block',
        marginTop: '20px'
    }
}));

const formSettings = {
    email: {
        required: true,
        format: 'email'
    }
};

const ForgotPassword = () => {
    const { t } = useTranslation();
    const [succesMessage, setSuccesMessage] = useState(false);
    const [form, submitForm] = useForm(formSettings);
    const [forgotPassword, { loading: mutationLoading, data }] = useMutation(FORGOT_PASSWORD);
    const quickscanAnonymousPersonId = useSelector((state) => state.auth.quickscanAnonymousPersonId);
    const classes = useStyles();

    const handleSubmit = () => {
        setSuccesMessage(false);
        submitForm().then((resp) => {
            if (resp.email) {
                forgotPassword({
                    variables: {
                        input: {
                            email: resp.email,
                            quickscanAnonymousPersonId: quickscanAnonymousPersonId
                        }
                    }
                });
            }
        });
    };

    useEffect(() => {
        if (data) {
            if (data.forgotPassword.result) {
                setSuccesMessage(true);
            }
        }
    }, [data]);

    const onKeyPress = (e) => {
        if (e.which === 13) {
            handleSubmit(e);
        }
    };

    return (
        <Grid className={classes.root} justify="center" container>
            <Grid className={classes.wrapper} item xs={12}>
                <Typography variant="h3">{t('forgotPassword.title')}</Typography>
                <Typography variant="body1">{t('forgotPassword.subTitle')}</Typography>
                <InputField
                    className={classes.input}
                    label={t('forgotPassword.emailLabel')}
                    type="email"
                    name="email"
                    onKeyPress={onKeyPress}
                    form={form}
                />
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                    label={t('forgotPassword.buttonLabel')}
                    className={classes.button}
                />
                <Link to="/" className={classes.link}>
                    <Typography>{t('general.back')}</Typography>
                </Link>
                {succesMessage && (
                    <Box mt={2}>
                        <Typography variant="body1">{t('forgotPassword.succesMessage')}</Typography>
                    </Box>
                )}
            </Grid>
            <LoaderModal loading={mutationLoading} />
        </Grid>
    );
};

export default ForgotPassword;
