import { gql } from 'graphql-tag';

export const GQL_GET_CA_MEETINGS = gql`
    query meetings($careerAdvisor: [Long!]) {
        meetings(filter: { careerAdvisor: $careerAdvisor }) {
            count
            meetings {
                id
                cancelledOn
                finishedOn
                plannedOn
                type
                code
                status
                createdOn
                owner {
                    id
                    email
                    profile {
                        fullName
                    }
                }
            }
        }
    }
`;

export const UPDATE_MEETING = gql`
    mutation updateMeeting($input: MeetingInput!) {
        updateMeeting(input: $input) {
            id
            status
            finishedOn
            owner {
                id
                email
            }
        }
    }
`;
