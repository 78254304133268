import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER } from 'api';
import Cookie from 'js-cookie';

const types = ['ADVISOR_MEETING_ONE', 'ADVISOR_MEETING_TWO', 'ADVISOR_MEETING_THREE'];
const titles = ['Eerste gesprek plannen', 'Tweede gesprek plannen', 'Derde gesprek plannen'];
const linkTitles = ['Plan je eerste gesprek', 'Plan je tweede gesprek', 'Plan je derde gesprek'];
const linkDoneTitles = [
    'Je hebt je eerste gesprek gepland',
    'Je hebt je tweede gesprek gepland',
    'Je hebt je derde gesprek gepland'
];

const meetingStatuses = {
    open: 'OPEN',
    planned: 'PLANNED',
    finished: 'FINISHED',
    cancelled: 'CANCELLED',
    archived: 'ARCHIVED'
};

const useCurrentUser = () => {
    const [userData, setUserData] = useState();
    const [userMeetings, setUserMeetings] = useState({
        firstMeeting: { status: 'open', title: titles[0], linkTitle: linkTitles[0], linkDoneTitle: linkDoneTitles[0] },
        secondMeeting: { status: 'open', title: titles[1], linkTitle: linkTitles[1], linkDoneTitle: linkDoneTitles[1] },
        thirdMeeting: { status: 'open', title: titles[2], linkTitle: linkTitles[2], linkDoneTitle: linkDoneTitles[2] }
    });
    const [userMeetingsDone, setUserMeetingsDone] = useState(false);
    const [hasPlannedMeeting, setHasPlannedMeeting] = useState(false);
    const [careerAdvisor, setCareerAdvisor] = useState();
    const [careerSteps, setCareerSteps] = useState();
    const [careerTasks, setCareerTasks] = useState();
    const [corporation, setCorporation] = useState();
    const [skillTest, setSkillTest] = useState({
        finished: false,
        reset: false,
        startedOn: null,
        archivedCount: 0
    });

    const [careerWizzardStatus, setCareerWizzardStatus] = useState(false);

    // queries
    const { loading, data, refetch } = useQuery(GET_USER, {
        fetchPolicy: 'no-cache'
    });

    useEffect(() => {
        if (!loading && data) {
            const user = data.currentUser;
            const meetings = Array.from(data.currentUser?.meetings || []);
            const careerAdvisor = data.currentUser?.careerProfile?.careerAdvisor;
            const careerStepsData = data.currentUser?.careerProfile?.careerSteps;
            const corporationData = data.currentUser?.corporation;
            const careerTasksData = data.currentUser?.careerProfile?.tasks;

            if (user) {
                setUserData({
                    email: user.email,
                    id: user.id,
                    firstName: user.profile.firstName,
                    lastName: user.profile.lastName,
                    fullName: user.profile.fullName,
                    meetingsBatch: user.meetingsBatch,
                    skillTestReset: user.skillTestReset,
                    skillTestStartedOn: user.skillTestStartedOn,
                    skillTestResetOn: user.skillTestResetOn,
                    skillTestArchivedCount: user.skillTestArchivedCount
                });
            }

            if (corporationData) {
                setCorporation(corporationData);
            }
            if (careerTasksData) {
                setCareerTasks(careerTasksData);
            }

            // Get the test from the steps
            if (careerStepsData) {
                setCareerSteps(careerStepsData);
                const test = careerStepsData
                    .map((step) => step.subSteps)
                    .flat()
                    .filter((item) => item.type === 'SKILL_TEST')[0];

                setSkillTest(() => {
                    return {
                        ...test,
                        archivedCount: user.skillTestArchivedCount
                    };
                });

                if (test.finished) {
                    setCareerWizzardStatus(true);
                }

                // check if there is a old advisor meeting
                const voucherStep = careerStepsData
                    .map((step) => step.subSteps)
                    .flat()
                    .filter((item) => item.type === 'ADVISOR_MEETING')[0];

                // if there is a voucher setCookie for popup
                var cookie = Cookie.get('voucherUser');
                if (voucherStep && voucherStep.finished && cookie !== 'true') {
                    Cookie.set('voucherUser', true, { expires: 365 });
                }
            }

            // set career advisor
            if (careerAdvisor) {
                setCareerAdvisor({
                    name: careerAdvisor.profile.fullName || '',
                    city: careerAdvisor.profile.city ? careerAdvisor.profile.city.province || '' : '',
                    phone: careerAdvisor.profile.phone || '',
                    mail: careerAdvisor.email,
                    avatar: careerAdvisor.profile.profilePicture && careerAdvisor.profile.profilePicture,
                    id: careerAdvisor.id
                });
                setCareerWizzardStatus(true);
            }

            // remove the archived meetings
            let countMeetings = meetings.length;
            while (countMeetings--) {
                if (meetings[countMeetings].status === meetingStatuses.archived) {
                    meetings.splice(countMeetings, 1);
                }
            }

            // Group meeting bij type
            const firstMeetings = meetings.filter((item) => item.type === types[0] && item.status);
            const secondMeetings = meetings.filter((item) => item.type === types[1] && item.status);
            const thirdMeetings = meetings.filter((item) => item.type === types[2] && item.status);

            // get the last first meeting and set status
            const lastFirstMeeting = firstMeetings[firstMeetings.length - 1];
            const firstStatus = getStatus(lastFirstMeeting);

            // get the last second meeting and set status
            const lastSecondMeeting = secondMeetings[secondMeetings.length - 1];
            const secondStatus = getStatus(lastSecondMeeting);

            // get the last third meeting and set status
            const lastThirdMeeting = thirdMeetings[thirdMeetings.length - 1];
            const thirdStatus = getStatus(lastThirdMeeting);

            // set the meetings content
            setUserMeetings((meeting) => ({
                firstMeeting: { ...meeting.firstMeeting, status: firstStatus, title: 'Eerste gesprek plannen' },
                secondMeeting: { ...meeting.secondMeeting, status: secondStatus, title: 'Tweede gesprek plannen' },
                thirdMeeting: { ...meeting.thirdMeeting, status: thirdStatus, title: 'Derde gesprek plannen' }
            }));

            // check if all meetings are done
            if (
                lastFirstMeeting &&
                (lastFirstMeeting.status === meetingStatuses.finished ||
                    lastFirstMeeting.status === meetingStatuses.planned) &&
                lastSecondMeeting &&
                (lastSecondMeeting.status === meetingStatuses.finished ||
                    lastSecondMeeting.status === meetingStatuses.planned) &&
                lastThirdMeeting &&
                (lastThirdMeeting.status === meetingStatuses.finished ||
                    lastThirdMeeting.status === meetingStatuses.planned)
            ) {
                setUserMeetingsDone(true);
            }
        }
    }, [data, loading]);

    const getStatus = (meeting) => {
        if (!meeting || meeting.status === meetingStatuses.cancelled) {
            return 'open';
        }
        if (meeting.status === meetingStatuses.planned) {
            setHasPlannedMeeting(true);
            setCareerWizzardStatus(true);
            return 'planned';
        }
        if (meeting.status === meetingStatuses.finished) {
            setCareerWizzardStatus(true);
            setHasPlannedMeeting(true);
            return 'done';
        }
        if (meeting.status === meetingStatuses.archived) {
            setCareerWizzardStatus(true);
            setHasPlannedMeeting(true);
            return 'done';
        }
    };

    return {
        loading: loading,
        userData: userData,
        userMeetings: userMeetings,
        userMeetingsDone: userMeetingsDone,
        hasPlannedMeeting: hasPlannedMeeting,
        skillTest: skillTest,
        careerAdvisor: careerAdvisor,
        careerSteps: careerSteps,
        refetchUserData: refetch,
        careerWizzardStatus: careerWizzardStatus,
        corporation: corporation,
        careerTasks: careerTasks
    };
};

export default useCurrentUser;
