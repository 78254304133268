import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/logo.svg';
import { useMutation } from '@apollo/client';
import { LOGOUT } from 'api';
import { Header } from 'shared';
import { logoutUser } from 'store/actions';
import { isEmpty } from 'helpers';
import { useHistory } from 'react-router-dom';
import { ROLE_NAMES } from 'const';

const AppHeader = (props) => {
    const { noLinks = false, noLogin = false } = props;
    const [logout] = useMutation(LOGOUT);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const [appLinks, setAppLinks] = useState([]);
    const user = useSelector((state) => state.auth.user);
    const appUser = isEmpty(user) ? undefined : user;

    const currentRole = useSelector((state) => state.auth.role);

    useEffect(() => {
        if (!currentRole) return;
        if (currentRole === ROLE_NAMES.CAREER_ADVISOR) {
            setAppLinks([
                {
                    path: '/gesprekken/overzicht',
                    linkText: 'Gesprekken'
                },
                {
                    path: '/mijn-documenten',
                    linkText: t('menu.documents')
                }
            ]);
        } else if (
            currentRole !== ROLE_NAMES.USER &&
            (currentRole === ROLE_NAMES.DI_ADVISOR ||
                currentRole === ROLE_NAMES.PROJECT_LEADER ||
                currentRole === ROLE_NAMES.DI_SPP)
        ) {
            setAppLinks([
                {
                    path: '/',
                    linkText: t('menu.dashboard')
                },
                {
                    path: '/mijn-documenten',
                    linkText: t('menu.documents')
                }
            ]);
        } else {
            setAppLinks([
                {
                    path: '/',
                    linkText: t('menu.dashboard')
                },
                {
                    path: '/quick-scan',
                    linkText: t('menu.quickScan')
                },
                {
                    path: '/mijn-loopbaanwijzer',
                    linkText: t('menu.careerGuide')
                },
                {
                    path:
                        currentRole === 'HR_USER'
                            ? `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}/hr?role=${currentRole}`
                            : `${process.env.REACT_APP_VACANCIES_FLOW_DOMAIN}?role=${currentRole}`,
                    linkText: t('menu.vacancyPortal'),
                    external: true,
                    target: '_self'
                },
                {
                    path: '/mijn-documenten',
                    linkText: t('menu.documents')
                },
                {
                    path: '/mijn-gegevens',
                    mobile: true,
                    linkText: t('menu.profile')
                },
                {
                    path: '/login',
                    mobile: true,
                    linkText: t('menu.login')
                }
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentRole]);

    const handleSignOut = () => {
        logout().then(() => {
            dispatch(logoutUser());
            history.push('/logout');
        });
    };

    return (
        <Header
            links={!noLinks ? appLinks : []}
            renderLogo={() => (
                <a href={process.env.REACT_APP_FLOW_WEB_DOMAIN} target="_blank" rel="noopener noreferrer">
                    <img src={logo} alt="logo" />
                </a>
            )}
            signOut={handleSignOut}
            headerBackground={true}
            user={appUser}
            showReadMore={false}
            closeNotifications={true}
            noLogin={noLogin}
        />
    );
};

export default AppHeader;
