import { gql } from 'graphql-tag';

export const GET_USER_FOLDERS = gql`
    query UserAssetFolders($roleId: Long!, $pagination: PaginationInput) {
        userAssetFolders(roleId: $roleId, pagination: $pagination) {
            assetFolders {
                id
                name
                isPrivate
                canDelete
                canCreateFiles
                canDeleteFiles
            }
        }
    }
`;

export const GET_USER_FOLDER_DATA = gql`
    query UserAssets($folderId: Long!, $pagination: PaginationInput) {
        userAssets(folderId: $folderId, pagination: $pagination) {
            count
            assets {
                id
                s3Id
                thumbnailS3Id
                name
                type
                contentType
                description
                size
                sharedFolder
                assetFolder {
                    id
                    name
                }
                url
                thumbnailUrl
                createdOn
            }
        }
    }
`;

export const UPLOAD_DOCUMENT = gql`
    mutation UploadDocument($file: Upload, $input: UploadDocumentInput) {
        uploadDocument(file: $file, input: $input) {
            asset {
                id
                contentType
                url
            }
        }
    }
`;

export const DELETE_DOCUMENT = gql`
    mutation DeleteDocument($id: Long!) {
        deleteDocument(id: $id) {
            success
        }
    }
`;
